import React from 'react';
import { Icon } from './Icon';

export interface LogoProps {
	isBig?: boolean,
	styles?: React.CSSProperties,
}

export const Logo: React.FC<LogoProps> = (props) => {

	const { isBig = false, styles } = props;

	return (
		<div style={styles}>
			<Icon name={isBig ? 'logo' : 'logoVertical'} />
		</div>
	);

}
