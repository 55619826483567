import { Controller } from 'react-hook-form';
import Select, { components, Props, MenuListProps, GroupBase, StylesConfig, ThemeConfig } from 'react-select';
import cn from 'classnames';

import { ControlledField, getState } from '../types';
import { IconArgs } from './Icon';
import { SquareIcon } from './SquareIcon';
import { theme as _theme } from '~/constants';

interface RSelectProps extends Props {
	note?: string,
	icon?: IconArgs | null
}

const MenuList: React.FC<MenuListProps<unknown, boolean, GroupBase<unknown>>> = ({ children, ...props }) => {

	return (
		<components.MenuList {...props}>
			{Array.isArray(children)
				? children.slice(0, 50)
				: children
			}
		</components.MenuList>
	);

}

const theme: ThemeConfig = (theme) => ({
	...theme,
	borderRadius: 0,
	spacing: {
		...theme.spacing,
		baseUnit: 7.5,
	},
	colors: {
		...theme.colors,
		text: 'black',
		primary: 'black'
	},
});

const styles: StylesConfig<unknown, boolean, GroupBase<unknown>> = {
	input: (provided) => ({
		...provided,
		color: '#fff',
		font: `400 14px '${_theme.font}', Sans-serif`,
	}),
	singleValue: (provided) => ({
		...provided,
		color: '#fff',
	}),
	control: (provided) => ({
		...provided,
		backgroundColor: '#2e2e2e',
		borderRadius: 0,
		boxShadow: 'none',
		border: 'none',
		cursor: 'pointer',
		':hover': {
			border: 'none',
		},
	}),
	menu: (provided) => ({
		...provided,
		zIndex: 30,
		boxShadow: 'none',
		// border: '1px solid #4d4d4d',
		backgroundColor: '#2e2e2e',
		borderRadius: 0,
		overflow: 'hidden',
	}),
	option: (provided) => ({
		...provided,
		cursor: 'pointer',
		backgroundColor: '#2e2e2e',
		':hover': {
			backgroundColor: '#363636',
		},
	}),
	multiValueLabel: (provided) => ({
		...provided,
		color: '#fff',
	}),
	multiValue: (provided) => ({
		...provided,
		backgroundColor: '#2f2f2f',
	}),
};

type CProps = ControlledField<RSelectProps>

export const RSelectInner: React.FC<CProps['inner']> = (props) => {

	const { icon, note, ...args } = props;

	const state = getState(args);

	return (
		<div className={cn('app--r-select', { invalid: !!state?.error })}>
			{icon && (
			<SquareIcon icon={icon} />
			)}
			<div className="app--r-select-container">
				<Select
					{...args}
					theme={theme}
					styles={styles}
					components={{
						MenuList,
						IndicatorSeparator: () => null }} />
				{note && <p className="note">{note}</p>}
				{!!state?.error?.message && (
				<p
					className="app-input--message"
					children={state.error.message} />
				)}
			</div>
		</div>
	);

}

export const RSelect: React.FC<CProps['outer']> = (props) => {

	if (!('control' in props)) {
		return <RSelectInner {...props} />
	}

	const {
		name = '',
		rules = {},
		control,
		defaultValue = '',
	} = props;

	return (
		<Controller
			name={name}
			rules={rules}
			control={control}
			defaultValue={defaultValue}
			render={({ field, fieldState }) => {

				const { value, onBlur, onChange } = field;

				return (
					<RSelectInner
						{...props}
						value={value}
						state={fieldState}
						onBlur={onBlur}
						onChange={onChange} />
				);

			}} />
	);

}
